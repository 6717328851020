<script setup lang="ts">
const { data } = useBonusesData();
</script>

<template>
	<div>
		<audio v-for="audio in data?.audios" :id="audio.name" :key="audio.name" :loop="'loop' in audio.arguments">
			<source :src="audio.ogg" type="audio/ogg" />
			<source :src="audio.mp3" type="audio/mp3" />
		</audio>
	</div>
</template>
